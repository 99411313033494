<template>
    <div class="faq-container">
        <div class="row-container">
            <Card class="faq-card base-card">
                <template #title>
                    <div class="table-header wwd-row wwd-sb">
                        <span @click="$store.dispatch('refreshIdToken')">FAQ (In progress..)</span>
                    </div>
                </template>
                <template #content>
                    <Accordion :activeIndex="[0]" :multiple="true">
                        <AccordionTab v-for="data, index in content" :header="data.question" :key="index">
                            <p>{{ data.answer }}</p>
                        </AccordionTab>
                    </Accordion>
                </template>
            </Card>
        </div>
    </div>
</template>
<script>
import Card from 'primevue/card';
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
export default {
    components: {
        Card,
        Accordion,
        AccordionTab
    },
    data() {
        return {
            content: [
                {question: 'How can I create a new scenario?', answer: 'Go to settings, then in the HMI Control tab, press the new scenario button, you can create a blank new scenario or copy an existing one.'},
                {question: 'What is the assets tab and why do I need it?', answer: 'In the assets tab, you can download and upload scenarios in the form of a json file. You can also manage your lists of simulation signals that are needed when creating new scenarios.'},
                {question: 'In the settings, when selecting the scenario, there is a checkbox "Show only for running model". What is it for?', answer: 'It allows you to filter scenarios based on their tags in terms of the model running on the cloud simubox.'},
                {question: 'How to control breakers?', answer: 'To open or close the breaker right click on it. To enter the state of forced breaker switching, press the scroll button, then open or close with the right mouse button.'},
                {question: 'After going to the home tab when the simulation is running, I get the error "signal is not defined in the simulation model".', answer: 'This means that the selected scenario contains elements with connected signals that are not present in the running model. Most likely the scenario was poorly created or the wrong scenario was chosen.'},
                {question: 'I can\'t connect to the selected simulation.', answer: 'Make sure your simubox is running and that all devices in the simulation are active. If the problem persists, please contact us.'},
                {question: 'What is the difference between toolkit ip and simulation ip in the settings tab?', answer: 'Simulation IP is the address to which WebSimHMI connects directly when the simulation is fully running. The Toolkit IP is the address you enter in the OpenVPN Toolkit.'},
            ]
        }
    }
}
</script>

<style scoped>
.faq-container>* {
    margin-top: 1rem;
    color: white;
}

.row-container {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.faq-card {
    height: 50em;
    width: 98vw;
    overflow: auto;
}

h2 {
    color: var(--font3) !important;
}
</style>